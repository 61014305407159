import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  QueryDocumentSnapshot
} from "@angular/fire/firestore";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
import "rxjs/add/operator/scan";
import "rxjs/add/operator/take";
import { QueryConfig } from "../../models";
import { ImageService } from "../image/image.service";
import { Timestamp } from "rxjs/internal/operators/timestamp";
import { FirestoreService } from "../firestore/firestore.service";

@Injectable()
export class ArticlesService {
  // Source data
  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);

  private query: QueryConfig;

  // Observable data
  data: Observable<any>;
  done: Observable<boolean> = this._done.asObservable();
  loading: Observable<boolean> = this._loading.asObservable();

  constructor(private afs: AngularFirestore, private imageService: ImageService, private firestore: FirestoreService) {}

  // Initial query sets options and defines the Observable
  // passing opts will override the defaults
  init(path: string, orderByField: string, isApproved: boolean, opts?: any) {
    const oldData = this._data.getValue();
    oldData.length = 0;
    this._data.next(oldData);
    this._done.next(false);
    this.query = {
      path,
      orderByField: orderByField,
      isApproved: isApproved,
      limit: 10,
      reverse: false,
      prepend: false,
      ...opts
    };

    const first = this.afs.collection(this.query.path, ref => {
      if (this.query.isApproved != null) {
        return ref
          .where("isApproved", "==", this.query.isApproved)
          .orderBy(this.query.orderByField, this.query.reverse ? "desc" : "asc")
          .limit(this.query.limit);
      } else {
        return ref
          .orderBy(this.query.orderByField, this.query.reverse ? "desc" : "asc")
          .limit(this.query.limit);
      }
    });

    this.mapAndUpdate(first);

    // Create the observable array for consumption in components
    this.data = this._data.asObservable().scan((acc, val) => {
      return this.query.prepend ? val.concat(acc) : acc.concat(val);
    });
  }

  // Retrieves additional data from firestore
  more() {
    const cursor = this.getCursor();

    const more = this.afs.collection(this.query.path, ref => {
      if (this.query.isApproved != null) {
        return ref
          .where("isApproved", "==", this.query.isApproved)
          .orderBy(this.query.orderByField, this.query.reverse ? "desc" : "asc")
          .limit(this.query.limit)
          .startAfter(cursor);
      } else {
        return ref
          .orderBy(this.query.orderByField, this.query.reverse ? "desc" : "asc")
          .limit(this.query.limit)
          .startAfter(cursor);
      }
    });
    this.mapAndUpdate(more);
  }

  async getScheduled(from: Date, to: Date): Promise<any[]> {
    const query = this.afs.collection(this.query.path, ref => {
      return ref.where('publishDate', ">=", this.firestore.timestamp.fromDate(from))
        .where('publishDate', "<", this.firestore.timestamp.fromDate(to))
        .orderBy("publishDate", "desc")
    });

    const articles = await query.get().toPromise();
    return articles.docs.map(snapshot => this.mapSnapshot(snapshot));
  }

  // Determines the doc snapshot to paginate query
  private getCursor() {
    const current = this._data.value;
    if (current.length) {
      return this.query.prepend
        ? current[0].doc
        : current[current.length - 1].doc;
    }
    return null;
  }

  // Maps the snapshot to usable format the updates source
  private mapAndUpdate(collection: AngularFirestoreCollection<any>) {
    if (this._done.value || this._loading.value) {
      return;
    }

    // loading
    this._loading.next(true);

    // Map snapshot with doc ref (needed for cursor)
    return collection
      .snapshotChanges()
      .do(arr => {
        let values = arr.map(snapshot => this.mapSnapshot(snapshot.payload.doc));

        // If prepending, reverse the batch order
        values = this.query.prepend ? values.reverse() : values;

        // update source with new values, done loading
        this._data.next(values);
        this._loading.next(false);

        // no more values, mark done
        if (!values.length) {
          this._done.next(true);
        }
      })
      .take(1)
      .subscribe();
  }

  private mapSnapshot<T>(document: QueryDocumentSnapshot<T>) {
    const id = document.id;
    const data = document.data();
    const imgUrl = this.imageService.getUrl(id, true);
    return { ...data, doc: document, id, imgUrl };
  }
}
