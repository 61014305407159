import { Injectable } from '@angular/core';

/*
Uses regular expressions to add or remove html rubies to text with Kanji
https://www.localizingjapan.com/blog/2012/01/20/regular-expressions-for-japanese-text/
*/
@Injectable()
export class RubiesService {
  toHtml(text: string) {
    return text.replace(
      /([^\u30A1-\u30FB\u3041-\u3096、。\s\w]+)[（\()]([\u3041-\u3096]+)[）	\)]/gim,
      '<ruby>$1<rt>$2</rt></ruby>'
    );
  }

  fromHtml(html: string) {
    return html
      .replace(/<ruby>/g, '')
      .replace(/<\/ruby>/g, '')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '\n\n')
      .replace(/<rt>/g, '（')
      .replace(/<\/rt>/g, '）')
      .replace(/<br\s*\/>/gi, '\n');
  }
}
