import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { DataService } from "../services/data/data.service";

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private router: Router, private data: DataService) {}

  canActivate(): Promise<boolean> {
    return new Promise(resolve => {
      const role = this.data.user.role;
      if (role === "admin") {
        return resolve(true);
      } else {
        this.router.navigate(["/dashboard"]);
        return resolve(false);
      }
    });
  }
}
