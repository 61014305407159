import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { User, Article } from "../../models";
import { map } from "rxjs/operators";
import "rxjs/add/operator/take";
import { ImageService } from "../image/image.service";

firebase.initializeApp(environment.firebaseConfig);

@Injectable({
  providedIn: "root"
})
export class FirestoreService {
  serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
  timestamp = firebase.firestore.Timestamp;

  constructor(private afs: AngularFirestore, private imageService: ImageService) {}

  add(collection, payload) {
    return firebase
      .firestore()
      .collection(collection)
      .add(payload);
  }

  set(collection, docID, payload) {
    let docRef = firebase
      .firestore()
      .collection(collection)
      .doc(docID);
    return docRef.set(payload);
  }

  delete(collection, docID) {
    return firebase
      .firestore()
      .collection(collection)
      .doc(docID)
      .delete();
  }

  update(collection, docID, payload) {
    return firebase
      .firestore()
      .collection(collection)
      .doc(docID)
      .update(payload);
  }

  get(collection, docID) {
    return firebase
      .firestore()
      .collection(collection)
      .doc(docID)
      .get();
  }

  async upload(articleId: string, file: Blob): Promise<string> {
    const ref = firebase.storage().ref();
    await ref.child(`articleImages/${articleId}.jpg`).put(file);
    return this.imageService.getUrl(articleId);
  }

  getUser(userId) {
    return this.afs
      .doc("users/" + userId)
      .snapshotChanges()
      .pipe(
        map(actions => {
          const id = actions.payload.id;
          const data = actions.payload.data() as User;

          return { id, ...data };
        })
      )
      .take(1);
  }

  getUsers() {
    return this.afs
      .collection("users", ref =>
        ref
          .where("role", "in", ["author", "admin"])
          .orderBy("createdAt", "desc")
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data() as User;

            return {
              id,
              ...data
            };
          })
        )
      )
      .take(1);
  }

  getArticle(articleId) {
    return this.afs
      .doc("articles/" + articleId)
      .snapshotChanges()
      .pipe(
        map(a => {
          const id = a.payload.id;
          const data = a.payload.data() as Article;
          const imgUrl = this.imageService.getUrl(id);
          return {
            id,
            imgUrl,
            ...data
          };
        })
      )
      .take(1);
  }

  getArticles(userId) {
    return this.afs
      .collection("articles", ref =>
        userId
          ? ref.where("authorId", "==", userId).orderBy("publishDate", "desc")
          : ref.orderBy("publishDate", "desc").limit(10)
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data() as Article;
            const imgUrl = this.imageService.getUrl(id);
            return {
              id,
              imgUrl,
              ...data
            };
          })
        )
      )
      .take(1);
  }

  getArticlesByUser(users: Array<string>) {
    return this.afs
      .collection("articles", ref =>
        ref.where("authorId", "in", users).orderBy("publishDate", "desc")
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data() as Article;
            const imgUrl = this.imageService.getUrl(id);
            return {
              id,
              imgUrl,
              ...data
            };
          })
        )
      )
      .take(1);
  }

  updateArticle(id: string, data: object): Promise<boolean> {
    return this.update("articles", id, data)
      .then(() => {
        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      });
  }

  deleteArticle(id: string): Promise<boolean> {
    return this.delete("articles", id)
      .then(() => {
        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      });
  }
}
