import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  PreloadAllModules,
  Route
} from "@angular/router";
import { NotFoundComponent } from "./pages/not-found/not-found.component";

const fallback: Route = {
  path: "**",
  component: NotFoundComponent
};

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./features/auth/auth.module").then(m => m.AuthModule)
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./features/dashboard/dashboard.module").then(
        m => m.DashboardModule
      )
  },
  fallback
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
