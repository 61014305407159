import { Injectable } from "@angular/core";
import { User, Article } from "../../models";
import { Subscription } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DataService {
  user: User;
  user$: Subscription;

  articles: Array<Article>;
  articles$: Subscription;

  authors: Array<User>;
  authors$: Subscription;

  editArticle: Article;
  editArticle$: Subscription;

  orderBy: string = "publishDate";
  arePublished: boolean = true;
  arePending: boolean = true;
  constructor() {}

  clearData() {
    if (this.user$) {
      this.user = undefined;
      this.user$.unsubscribe();
    }

    if (this.articles$) {
      this.articles = undefined;
      this.articles$.unsubscribe();
    }

    if (this.authors$) {
      this.authors = undefined;
      this.authors$.unsubscribe();
    }

    if (this.editArticle$) {
      this.editArticle = undefined;
      this.editArticle$.unsubscribe();
    }
  }
}
