import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot } from "@angular/router";
import { DataService } from "../services/data/data.service";
import { FirestoreService } from "../services/firestore/firestore.service";

@Injectable()
export class ArticleGuard implements CanActivate {
  articleId: String;
  constructor(
    private router: Router,
    private data: DataService,
    private firestore: FirestoreService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.articleId = route.params["articleId"];
    return new Promise(resolve => {
      const userId = this.data.user.id;
      const role = this.data.user.role;
      const articles = this.data.articles;
      if (role === "admin") {
        if (articles) {
          const article = articles.find(x => x.id === this.articleId);
          if (article) {
            this.data.editArticle = article;
            return resolve(true);
          } else {
            this.router.navigate(["/not-found"]);
            return resolve(false);
          }
        } else {
          this.data.editArticle$ = this.firestore
            .getArticle(this.articleId)
            .subscribe(doc => {
              this.data.editArticle = doc;
              return resolve(true);
            });
        }
      } else {
        if (articles) {
          const article = articles.find(x => x.id === this.articleId);
          if (article) {
            this.data.editArticle = article;
            return resolve(true);
          } else {
            this.router.navigate(["/not-found"]);
            return resolve(false);
          }
        } else {
          this.data.articles$ = this.firestore
            .getArticles(userId)
            .subscribe(doc => {
              this.data.articles = doc;
              const article = doc.find(x => x.id === this.articleId);
              if (article) {
                console.log(article);
                
                this.data.editArticle = article;
                return resolve(true);
              } else {
                this.router.navigate(["/not-found"]);
                return resolve(false);
              }
            });
        }
      }
    });
  }
}
