import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FuriganaService {
  serviceUrl = 'https://furiganiser-jp-wctd3oeieq-an.a.run.app/encode';
  serviceUrlHtml = 'https://furiganiser-jp-wctd3oeieq-an.a.run.app/encode-html';

  constructor(private http: HttpClient) {}

  async getFurigana(text: string): Promise<FuriganaText[]> {
    return await this.http.post<FuriganaText[]>(this.serviceUrl, text, {
      headers: {
        'Content-Type': 'text/plain'
      },
    }).toPromise();
  }

  async getFuriganaHtml(text: string): Promise<string> {
    const html = await this.http.post(this.serviceUrlHtml, text, {
      headers: {
        'Content-Type': 'text/plain'
      },
      responseType: 'text'
    }).toPromise();

    return this.breakParagraphs(html);
  }

  private breakParagraphs(html: string): string {
    const paragraphs = html.split(/<br\s*\/><br\s*\/>/gi);
    if (paragraphs.length === 1) {
      return paragraphs[0];
    }
    return paragraphs.map(paragraph => `<p>${paragraph}</p>`).join('');
  }
}

export interface FuriganaTextPart {
  text: string;
  reading?: string;
  explicit?: boolean;
}

export interface FuriganaText {
  text: string;
  parts?: FuriganaTextPart[];
}
