import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthModule } from 'src/app/features/auth/auth.module';

export interface ArticleStats {
  percentage: number;
  articleCount: number;
  name: string;
}

export interface AuthorStats {
  nhkStats: ArticleStats[];
  originalStats: ArticleStats[];
}

@Injectable()
export class StatisticsService {

  constructor(private afs: AngularFirestore) {}

  async getLevelStats(level: string, nhk: boolean): Promise<ArticleStats> {
    const articles = await this.afs.collection('article-feed', ref => {
      let query = ref.where('stats.averageLevel', '==', level);
      if (nhk) {
        query = query.where('provider', '==', 'NHK');
      } else {
        query = query.where('provider', '==', 'JReader original');
      }
      return query;
    }).get().toPromise();
    const count = articles.size;
    return {
      name: level,
      percentage: 0,
      articleCount: count
    }
  }

  async getArticleStatsForAuthors(nhk: boolean): Promise<ArticleStats[]> {
    const levels = await Promise.all(['n1', 'n2', 'n3', 'n4', 'n5'].map((level) => this.getLevelStats(level, nhk)))
    const total = levels.reduce((total, level) => total + level.articleCount, 0);
    if (total === 0) return levels
    levels.forEach(level => level.percentage = Math.round(level.articleCount / total * 100));
    return levels
  }

  async getArticleStats(): Promise<AuthorStats> {
    const nhkStats = await this.getArticleStatsForAuthors(true);
    const originalStats = await this.getArticleStatsForAuthors(false);
    return {
      nhkStats: nhkStats,
      originalStats: originalStats
    };
  }
}