// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDxAlLAwmJH_DIrncI46vpAjW1J0RDWSPo",
    authDomain: "japanese-reader-test.firebaseapp.com",
    projectId: "japanese-reader-test",
    storageBucket: "japanese-reader-test.appspot.com",
    messagingSenderId: "9539387440",
    appId: "1:9539387440:web:2f9be88d312285d9fd2df5",
    measurementId: "G-HZLZ6WQ3RX",
    timestampsInSnapshots: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
