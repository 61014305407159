import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";
import { FirestoreService } from "../services/firestore/firestore.service";
import { DataService } from "../services/data/data.service";

@Injectable()
export class AuthService {
  constructor(
    public afAuth: AngularFireAuth,
    private firestore: FirestoreService,
    private data: DataService
  ) {}

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          const user = res.user;
          const isNew = res.additionalUserInfo.isNewUser;
          this.validateNewUser(user, isNew, "facebook", resolve);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      this.afAuth.auth.signInWithPopup(provider).then(
        res => {
          const user = res.user;
          const isNew = res.additionalUserInfo.isNewUser;
          this.validateNewUser(user, isNew, "google", resolve);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            const user = res.user;
            const payload = {
              email: user.email,
              name: value.name,
              role: "author",
              provider: "email",
              isApproved: false,
              createdAt: firebase.firestore.FieldValue.serverTimestamp()
            };
            this.firestore.set("users", user.uid, payload).then(() => {
              resolve(false);
            }).catch(reject);
          },
          err => reject(err)
        );
    });
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            const user = res.user;
            this.data.user$ = this.firestore
              .getUser(user.uid)
              .subscribe(doc => {
                if (doc.isApproved) {
                  this.data.user = doc;
                  resolve(true);
                } else {
                  resolve(false);
                }
              });
          },
          err => reject(err)
        );
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.data.clearData();
        this.afAuth.auth.signOut();
        resolve();
      } else {
        reject();
      }
    });
  }

  validateNewUser(user, isNew, provider, resolve) {
    if (isNew) {
      const payload = {
        email: user.email,
        name: user.displayName,
        role: "author",
        provider,
        isApproved: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      this.firestore.set("users", user.uid, payload).then(() => {
        resolve(false);
      });
    } else {
      this.data.user$ = this.firestore.getUser(user.uid).subscribe(doc => {
        if (doc.isApproved) {
          this.data.user = doc;
          resolve(true);
        } else {
          resolve(false);
        }
      });
    }
  }
}
