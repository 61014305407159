import { RubiesService } from './core/services/rubies/rubies.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './core/auth/auth.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from './core/auth/auth.guard';
import { UserService } from './core/auth/user.service';
import { ToastrModule } from 'ngx-toastr';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { DataService } from './core/services/data/data.service';
import { AdminGuard } from './core/auth/admin.guard';
import { ArticlesService } from './core/services/articles/articles.service';
import { ArticleGuard } from './core/auth/article.guard';
import { LoginGuard } from './core/auth/login.guard';
import { FuriganaService } from './core/services/furigana/furigana.service';
import { HttpClientModule } from '@angular/common/http';
import { StatisticsService } from './core/services/statistics/statistics.service';

import * as Sentry from '@sentry/browser';
import { SentryErrorHandler } from './utils/sentry-error-handler';

Sentry.init({
  dsn: 'https://60ffa758b1354ebab32ac8c6e3fb034a@o434426.ingest.sentry.io/5391470',
  integrations: [new Sentry.Integrations.TryCatch({
    XMLHttpRequest: false,
  })],
});

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    HttpClientModule,
    ToastrModule.forRoot()
  ],
  providers: [
    AuthService,
    AuthGuard,
    UserService,
    DataService,
    AdminGuard,
    ArticlesService,
    StatisticsService,
    ArticleGuard,
    LoginGuard,
    RubiesService,
    FuriganaService,
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
