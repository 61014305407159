import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class ImageService {
  getUrl(articleId: string, burstCache: Boolean = false) : string {
    const cacheBurst = burstCache ? `&cacheBurst=${Date.now()}` : '';
    return `https://firebasestorage.googleapis.com/v0/b/${environment.firebaseConfig.storageBucket}/o/articleImages%2F${articleId}.jpg?alt=media${cacheBurst}`;
  }
}
