import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { UserService } from "../auth/user.service";
import { FirestoreService } from "../services/firestore/firestore.service";
import { User } from "../models";
import { DataService } from "../services/data/data.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
    private firestore: FirestoreService,
    private data: DataService
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        async user => {
          await this.getUser(user.uid);

          const role = this.data.user.role;
          const userId = this.data.user.id;
          if (role === "author") {
            this.data.articles$ = this.firestore
              .getArticles(userId)
              .subscribe(doc => {
                this.data.articles = doc;
                return resolve(true);
              });
          } else {
            return resolve(true);
          }
        },
        err => {
          this.router.navigate(["/login"]);
          return resolve(false);
        }
      );
    });
  }

  getUser(userId): Promise<String> {
    return new Promise(resolve => {
      this.data.user$ = this.firestore.getUser(userId).subscribe(doc => {
        this.data.user = doc;
        resolve();
      });
    });
  }
}
